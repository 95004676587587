import {call, put, takeLatest} from 'redux-saga/effects';

import {operations} from './operations';
import {actions} from './modules';

function* watchApplicationsActions() {
    yield takeLatest(actions.getSchedule, getSchedule);
    yield takeLatest(actions.scheduleReset, scheduleReset);
}

function* getSchedule({payload}) {
    const {serviceCode, stateCode, servicePlanId, expandDay, leadId} = payload;

    try {
        const response = yield call(operations.getSchedule, {serviceCode, stateCode, servicePlanId, expandDay, leadId});
        yield put(actions.getScheduleSuccess(response.data));
    } catch (error) {
        const response = {
            data: error.response.data,
            status: error.response.status,
            statusText: error.response.statusText,
        };
        yield put(actions.getScheduleFail(response));
    }
}

function* scheduleReset() {
    yield put(actions.scheduleResetSuccess());
}

export const sagas = watchApplicationsActions;